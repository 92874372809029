
.random-paper {
    display: flex;
    flex-direction: column;
    height: 96%;
    padding: 30px;
    color: #13131b;
    .form {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: calc(100% - 128px)
    }
    ::v-deep(.el-scrollbar__wrap) {
        overflow-x: hidden;
    }
    .form-box {
        flex: 1;
        width: 520px;
        span {
            margin: 0 10px;
            white-space: nowrap;
        }
        .set-type-question {
            .hint {
                color: #999;
            }
            ::v-deep .el-input {
                width: 180px;
            }
            .type-input-list {
                width: 555px;
                transform: translateX(-9%);
                .type-input-item:nth-child(1) {
                    margin-top: 10px;
                }
                .type-input-item {
                    display: flex;
                    margin-bottom: 20px;
                }
            }
        }
        .total-score {
            // transform: translateX(39%);
            ::v-deep .el-input {
                width: 180px;
            }
        }
        .type-form-item {
            margin-bottom: 0;
        }

        .row_box {
            .title {
                display: flex;
                align-items: center;
                overflow: hidden;
            }
            .content {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 12px;
                margin-left: 33px;
                .content_box {
                    width: 100%;
                    border: 1px solid #DCDCDC;
                    border-radius: 4px;
                    .content_box_header {
                        background-color: #F2F4F7;
                        border-bottom: 1px solid #DCDCDC;
                        .content_box_header_text {
                            margin-left: 24px;
                            padding: 10px 0;
                            font-size: 16px;
                            color: #3E3E3E;
                        }
                    }
                    .content_box_content {
                        .content_box_header_text {
                            padding: 10px 0 10px 24px;
                            box-sizing: border-box;
                            font-size: 16px;
                            color: #3E3E3E;
                        }
                        .border_bootom {
                            border-bottom: 1px solid #DCDCDC;
                            border-left: 1px solid #DCDCDC;
                            :deep(.el-input__wrapper) {
                                background-color: #FFFFFF;
                                width: 100%;
                                height: 32px;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }

                .border_red {
                    ::v-deep(.el-input__inner) {
                        border-color: #ff0000;

                        &:hover {
                            border-color: #ff0000
                        }
                    }
                }
            }
        }
    }
    .footer-button {
        display: flex;
        justify-content: center;
        // margin-bottom: 28px;
        height: 68px;
        ::v-deep .el-button {
            width: 80px;
            height: 40px;
        }
        ::v-deep .blue-button {
            background-color: #1122d8;
            border-color: #1122d8;
        }
    }
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
